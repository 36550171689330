import $ from 'jquery';

const _LanguageKorean = () => {
    return {
        'menu-home': '홈',
        'menu-wallet': '지갑/채굴',
        'menu-repository': 'Repository',
        'menu-openSource': '오픈 소스',
        'whitepaper-title': 'Bitcoin Krypton 백서',
        'lang-text-EN': '영어',
        'lang-text-KO': '한국어',
        'lang-text-VI': '베트남어',
        'lang-text-ZH': '중국어',
        'mode-wallet': '지갑',
        'mode-guide': 'Guide',
        'root-banner-button': "Let's Start",
        'home-content-header-mining': '채굴하기',
        'home-content-header-wallet': '지갑 생성',
        'home-content-body-top': '비트코인 크립톤은 비트코인 ​​백서에 따라 신뢰할 수 있는 제3자의 개입 없이 운영될 수 있는 P2P 금융 프로그램을 만드는 목적을 완전히 충족시키기 위해 개발되었습니다.',
        'home-content-body-bottom': '<br/>2009년부터 운영되고 있는 비트코인 ​​블록체인은 2017년부터 세그윗을 적용해 왔으며, 이는 사토시 나카모토의 "제3자 개입 없음"이라는 슬로건을 심각하게 훼손했습니다.<br/>​<br/>라이트닝 네트워크는 비트코인과 관련이 없는 제3자 네트워크입니다.<br/>​<br/>결과적으로 비트코인은 가치를 잃었습니다.​<br/>​<br/>블록체인의 시작은 필연적으로 어떤 외부적 힘(제3자의 개입)에 의해 만들어집니다. <br/>그러나 그 이후에는 어떤 외부적 힘의 개입도 없어야 하며, 이는 블록체인을 완전하게 만드는 것에 대한 철학적 고려에서 파생됩니다.​<br/>​<br/>비트코인 크립톤은 향후 업데이트가 필요하지 않으며 신뢰할 수 있는 제3자의 개입이 필요없는 지속 가능한 블록체인입니다.',
        'home-content-tail-title': '그 전제는 다음과 같습니다.',
        'home-content-tail-text0': '제네시스 블록을 포함한 최초의 원본 소스 코드는 과거에 생성되었으며 미래에 외부의 어떤 힘에 의해서도 변경될 수 없습니다.',
        'home-content-tail-text1': '이것은 원래 소스 코드를 어떤 이유로도 변경할 수 없다는 선언입니다.<br/> 그러나 100% 가역적이고 원래 소스 코드와 호환되는 형태로 새로운 프로그램 언어로 이식하는 자유는 허용됩니다.',
        'home-content-tail-text2': "뉴턴의 사과는 자연 법칙의 발견입니다.<br/> 사과는 위에서 떨어지고, 이것은 좋고 나쁨의 문제가 아니라 법의 문제입니다.<br/> 비트코인 ​​크립톤의 초기 시절에 설정된 규칙은 어떤 이유로도 변경할 수 없습니다.",
        'home-content-tail-text3': '본 홈페이지는 비트코인 ​​크립톤의 초기 정착을 목적으로 만들어졌으며, 단기간만 존재합니다.<br/> 도메인은 개인이나 단체 등 제3자의 자산이므로 비트코인 ​​크립톤의 존립에 영향을 미칠 수 있는 도메인은 10년간만 운영되며 그 이후에는 사용되지 않습니다.',
        'home-content-tail-text4': '제네시스 블록을 포함한 메인넷을 브로드캐스팅하는 시드 노드는 당분간(10년) 인터넷 도메인에 의존할 것입니다.<br/> 그러나 브로드캐스팅되는 서버 정보는 앞으로 더 이상 제공되지 않을 것이며, 이를 자동화하는 프로그램의 개선은 미래 개발자를 대상으로 합니다.',
        'home-content-tail-text5': '원래 시드 노드는 사라지고, 시드 노드 없이 블록체인을 운영하는 일은 미래 세대에게 맡겨지게 됩니다.',
        'home-content-tail-text6': '처음에 제공된 지갑, 익스플로러, 소스코드, 백서 등을 저장하는 모든 장소는 관리 주체가 사라질 수 있으므로 신뢰해서는 안 되며, 모든 증명은 2024년 11월 1일 bitcoinkrypton.org를 통해 발표된 내용을 기반으로 해야 합니다.',
        'home-content-tail-text7': '비트코인 ​​크립톤은 P2P 금융 프로그램으로 업데이트가 없고, 미래에도 관리 주체가 없을 것이며, 이는 가장 완벽한 블록체인 기반 암호화폐의 탄생에 대한 이야기입니다.',
        'mode-miner': '채굴',
        'mining-text-more': '더 보기',
        'mining-text-hide': '숨기기',
        'mining-intro': `비트코인 크립톤을 획득하는 가장 기본적인 방법은 작업 증명(PoW)에 참여하는 것입니다.<br/><br/>작업 증명에 참여하려면 웹페이지 상단의 채굴을 선택하면 아래 화면이 나옵니다.`,
        'mining-text': `재생 버튼을 누르면, 컴퓨터의 CPU를 사용하여 비트코인 ​​크립톤 채굴에 참여하게 됩니다.​<br/> 채굴 시, 컴퓨터에 최적화된 채굴 Pool에 연결되며, 여러 사람과 함께 채굴할 수 있게 됩니다.​<br/> 채굴 Pool의 채굴자 수가 증가할수록 채굴의 효율성은 감소합니다.​<br/>​<br/>비트코인 크립톤은 JavaScript 머신 기반 채굴을 지원하며, 대용량 슈퍼 노드의 채굴을 적극적으로 제한하기 때문에 원래 비트코인의 채굴보다 훨씬 쉽습니다.<br/> 모바일 환경에서도 채굴이 가능합니다.​<br/>​<br/> 주저하지 말고 참여하세요!​<br/>​<br/>`,
        'donation-start': 'Donation 시작',
        'donation-finish': 'Donation 완료',
        'donation-text': `비트코인 크립톤은 Donation을 통해서도 얻을 수 있습니다.<br/> Donation에 참여를 하시면 비트코인 크립톤의 초기 안착에 큰 힘이 됩니다.<br/><br/> Donation은 USDT로 참여하실 수 있으며, Donation 주소는 아래와 같습니다.<br/><br/> Donation 주소: 0xbc0889EBeD112B6fDc8A3Eca1cb1E009926D386f<br/> Donation용 코인: USDT(Ethereum, Polygon, Binance)<br/> Donation 방법: Donation 주소로 USDT를 보내면, 보낸 USDT에 비례하여 보낸 주소로 비트코인 ​​크립톤이 재전송됩니다.<br/><br/> Donation에 따른 보상 정책<br/> 총 3단계의 도네이션이 있습니다.<br/><br/> 1단계는 1 USDT당 1 BTCK를 보상하며, 총 수량은 100,000 BTCK입니다.<br/> 2단계는 2 USDT당 1 BTCK를 보상하며, 총 수량은 200,000 BTCK입니다.<br/> 3단계는 3 USDT당 1 BTCK를 보상하며, 총 수량은 300,000 BTCK입니다.<br/>`,
        'referral-title': '추천인',
        'accounts-wallet-referral': '추천인 링크',
        'referral-link-text': '기본 링크:',
        'copy-link': '링크 복사',
        'referral-text-top': `생성된 지갑주소 하단 주소에 해당하는 레퍼럴 버튼이 있으며, 레퍼럴을 통해 링크를 공유하여 레퍼럴 BTCK를 보상받을 수 있습니다.<br/>EX) 공유하신 200개의 링크에 유저들이 접속하게 되면 1 BTCK의 레퍼럴 보상을 받을 수 있습니다.`,
        'referral-text-bottom': `참여 방법은 다음과 같습니다.<br/><br/> 1. 추천 링크 만들기<br/> 지갑 페이지를 방문하여 링크를 만듭니다. <br/>링크는 다음과 같습니다.<br/> - 기본 링크: 다른 사람에게 직접 공유합니다.<br/> - Shortner 링크: 쉽게 공유할 수 있는 더 간결한 버전입니다.<br/> - HTML Widgets Code: 이 위젯 코드를 블로그나 웹사이트에 추가합니다.<br/><br/> 2. 클릭합니다.<br/> 링크를 친구, 블로그 또는 소셜 미디어에 보냅니다.<br/> 보낸 링크를 클릭해서 Bitcoin Krypton 웹사이트에 방문합니다.<br/><br/> 3. 보상을 얻습니다.<br/> 링크를 클릭한 사람마다 0.005 BTCK를 얻습니다.<br/><br/>- 레퍼럴 보상 이벤트는 2025년 6월 30일까지 참여하실 수 있습니다.<br/> - 추천 링크로 다른 유저가 참여만해도 0.005 BTCK를 얻으실 수 있습니다.<br/> - 동일한 IP로 연속적으로 레퍼럴 요청이 있을 시 레퍼럴 보상이 누적되지 않을 수 있습니다.<br/> - 동일한 IP로는 하루에 최대 3회 레퍼럴 이벤트를 참여하실 수 있습니다.<br/> - 레퍼럴 보상은 매주 월요일에 진행됩니다.<br/> - 레퍼럴 보상 누적 수량은 확인하실 수 없으며, 누적된 수량은 아래 지갑주소에서 확인 하실 수 있습니다.<br/> -0xf5d52cbc404d5b1d76457a67ca92901bbfa55e3c`,
    }
}

const _LanguageVietnamese = () => {
    return {
        'menu-home': 'Trang Chủ',
        'menu-wallet': 'Ví/Khai Thác',
        'menu-repository': 'Thư viện',
        'menu-openSource': 'Mã Nguồn Mở',
        'whitepaper-title': "White Paper của Bitcoin Krypton",
        'lang-text-EN': 'Tiếng Anh',
        'lang-text-KO': 'Tiếng Hàn',
        'lang-text-VI': 'Tiếng Việt',
        'lang-text-ZH': 'Tiếng Trung',
        'mode-wallet': 'Ví',
        'mode-guide': 'Guide',
        'root-banner-button': "Let's Start",
        'home-content-header-mining': 'Bắt Đầu Đào',
        'home-content-header-wallet': 'Tạo Ví',
        'home-content-body-top': 'Bitcoin Krypton được phát triển nhằm hoàn thiện hoàn toàn mục tiêu ban đầu của chương trình tài chính P2P - hoạt động mà không cần sự can thiệp của bên thứ ba đáng tin cậy và phù hợp với bản gốc Whitepaper của Bitcoin.',
        'home-content-body-bottom': `<br/>Mạng lưới Bitcoin blockchain, đã hoạt động từ năm 2009, và áp dụng công nghệ SegWit từ năm 2017, chính điều này đã làm suy yếu nền tảng ban đầu của ông Satoshi Nakamoto là "không có sự can thiệp từ bên thứ ba."<br/><br/>Bởi vì, Lightning Network là một mạng lưới bên thứ ba, hoàn toàn không có kết nối trực tiếp với Bitcoin.<br/><br/>Hệ quả là, Bitcoin đã mất đi giá trị cốt lõi của mình.<br/><br/>Mặc dù, một blockchain khi khởi đầu không thể tránh khỏi sự can thiệp từ bên ngoài (ví dụ như sự can thiệp của bên thứ ba) nhưng sau đó không nên có bất kỳ sự can thiệp nào từ bên ngoài. <br/>Đây là triết lý căn bản về điều làm cho blockchain trở nên hoàn thiện.<br/><br/>Bitcoin Krypton đại diện cho một blockchain bền vững, không yêu cầu cập nhật trong tương lai và không chịu sự can thiệp của bên thứ ba đáng tin cậy.`,
        'home-content-tail-title': 'Tiền đề cơ bản cho điều này được xây dựng như sau:',
        'home-content-tail-text0': 'Mã nguồn gốc ban đầu, bao gồm khối nguyên thủy (Block 0), được tạo ra trong quá khứ và bất biến.<br/> Một khi đã được tạo ra, nó không thể bị thay đổi hay xóa.',
        'home-content-tail-text1': 'Một tuyên bố rằng mã nguồn gốc không thể bị thay đổi vì bất kỳ lý do nào.<br/>Tuy nhiên, việc chuyển đổi sang một ngôn ngữ lập trình mới, dưới hình thức 100% có thể đảo ngược và tương thích với mã nguồn ban đầu, được cho phép.',
        'home-content-tail-text2': "Quả táo của Newton là biểu tượng cho việc khám phá ra quy luật tự nhiên. <br/>Một quả táo rơi từ trên cao xuống, không phải vì nó tốt hay xấu, mà vì đó là quy luật.<br/> Tương tự, các quy tắc được đặt ra từ những ngày đầu của Bitcoin là bất biến và không thể thay đổi vì bất kỳ lý do nào.",
        'home-content-tail-text3': 'Trang web này được tạo ra với mục đích hỗ trợ ban đầu để ổn định Bitcoin Krypton và sẽ chỉ tồn tại trong một đoạn thời gian giới hạn. <br/>Vì tên miền là tài sản thuộc sở hữu của một bên thứ ba, chẳng hạn như cá nhân hoặc tổ chức, tên miền có khả năng ảnh hưởng đến sự tồn tại của Bitcoin Krypton chỉ được vận hành trong vòng 10 năm và sẽ không được sử dụng sau đó.',
        'home-content-tail-text4': 'Nút mầm (seed node) chịu trách nhiệm phát sóng mạng lưới chính, bao gồm cả khối nguyên thủy, sẽ phụ thuộc vào tên miền internet trong thời gian ngắn (10 năm). <br/>Tuy nhiên, thông tin máy chủ dùng để phát sóng sẽ không còn được cung cấp sau thời gian đó, và việc cải tiến chương trình tự động hóa quy trình này sẽ do các nhà phát triển tương lai đảm nhận.',
        'home-content-tail-text5': 'Nút mầm sẽ biến mất, và trách nhiệm vận hành blockchain sẽ được giao lại cho các thế hệ phát triển tương lai.',
        'home-content-tail-text6': 'Tất cả các nguồn lưu trữ như ví tiền điện tử, trình khám phá blockchain (explorers), mã nguồn, white paper, v.v.,... được cung cấp ban đầu không nên được xem là đáng tin cậy tuyệt đối, vì các thực thể quản lý những nguồn này có thể biến mất. <br/>Mọi bằng chứng cần dựa trên nội dung chính thức được công bố qua bitcoinkrypton.org vào ngày 1 tháng 11 năm 2024.',
        'home-content-tail-text7': 'Bitcoin Krypton là một chương trình tài chính P2P hoàn hảo, không yêu cầu bất kỳ bản cập nhật nào trong tương lai và sẽ không có bất kỳ thực thể quản lý nào tham gia vào quá trình vận hành.<br/> Điều này đánh dấu sự ra đời của loại tiền điện tử dựa trên blockchain hoàn chỉnh và tối ưu nhất.',
        'mode-miner': 'Đang Khai Thác',
        'mining-text-more': 'Đọc thêm',
        'mining-text-hide': 'Ẩn',
        'mining-intro': `Cách cơ bản nhất để kiếm Bitcoin Krypton là tham gia vào cơ chế Proof-of-Work (PoW).<br/><br/>Để tham gia cơ chế POW, bạn hãy truy cập mục Khai thác ở phần đầu trang web. Tại đó, bạn sẽ thấy màn hình như bên dưới.`,
        'mining-text': `Bằng cách nhấn nút phát, bạn sẽ kích hoạt việc khai thác Bitcoin Krypton bằng CPU của máy tính.​<br/> Khi bạn tham gia Khai Thác, trong số các pool Khai Thác hiện có, hệ thống sẽ kết nối bạn với một Pool Khai Thác được tối ưu hóa cho thiết bị của bạn. ​<br/> Điều này cho phép nhiều người Khai Thác cùng lúc. Tuy nhiên, khi số lượng người Khai Thác tăng lên, hiệu quả Khai Thác sẽ giảm xuống.​<br/>​<br/> Tham gia càng sớm, cơ hội nhận được nhiều Bitcoin Krypton càng cao!​<br/>​<br/> Bitcoin Krypton hỗ trợ Khai Thác dựa trên máy JavaScript, và vì nó tích cực hạn chế việc Khai Thác của các siêu nút có dung lượng lớn nên dễ dàng hơn so với việc Khai Thác Bitcoin. <br/>Việc Khai Thác cũng có thể thực hiện được ngay cả trong môi trường di động.<br/>​<br/>Còn chần chừ gì, hãy tham gia ngay hôm nay!​<br/>​<br/>`,
        'donation-start': 'Bắt đầu Donation',
        'donation-finish': 'Kết thúc Donation',
        'donation-text': `Bạn có thể nhận Bitcoin Krypton thông qua việc Donation.<br/> Sự ủng hộ của các bạn sẽ đóng góp rất lớn vào sự khởi đầu thành công của Bitcoin Krypton.<br/><br/> Thông tin đóng góp<br/><br/>Địa chỉ nhận: 0xbc0889EBeD112B6fDc8A3Eca1cb1E009926D386f<br/> Loại coin chấp nhận: USDT(Ethereum, Polygon, Binance)<br/>Cách ủng hộ: Nếu bạn gửi USDT đến địa chỉ trên, số lượng Bitcoin Krypton sẽ được gửi lại cho địa chỉ ví mà bạn đã sử dụng, tương ứng với số USDT bạn đã gửi.<br/><br/>Chính sách thưởng dựa trên các đóng góp<br/> Bao gồm 3 giai đoạn.<br/><br/> Giai đoạn 1 : 1 BTCK cho mỗi 1 USDT, tổng 100,000 BTCK<br/> Giai đoạn 2 : 1 BTCK cho mỗi 2 USDT, tổng 200,000 BTCK<br/> Giai đoạn 3 : 1 BTCK cho mỗi 3 USDT, tổng 300,000 BTCK<br/>`,
        'referral-title': 'Giới Thiệu Liên Kết',
        'accounts-wallet-referral': 'Referral Link',
        'referral-link-text': 'Standard Link:',
        'copy-link': 'Copy Link',
        'referral-text-top': `Có một nút Referral nằm bên dưới "Địa chỉ ví " được tạo.<br/>Bằng cách chia sẻ liên kết giới thiệu, bạn sẽ nhận được phần thưởng BTCK.​​<br/>Ví dụ, nếu 200 người dùng truy cập vào liên kết bạn đã chia sẻ, bạn sẽ nhận được phần thưởng giới thiệu là 1 BTCK.`,
        'referral-text-bottom': `Cách thức hoạt động:<br/><br/> 1. Tạo liên kết giới thiệu<br/> Vào trang Ví để tạo liên kết giới thiệu của bạn. <br/>Có 3 lựa chọn:<br/> - Liên kết chuẩn: Chia sẻ trực tiếp với mọi người.<br/> - Shortener Link: Phiên bản ngắn gọn hơn, dễ dàng chia sẻ.<br/> - HTML Widgets Code: Nhúng widget này vào blog hoặc website của bạn.<br/><br/> 2. Người nhận nhấn vào liên kết.<br/> Khi bạn gửi liên kết giới thiệu đến bạn bè, chia sẻ trên blog cá nhân hoặc mạng xã hội.<br/>Họ sẽ nhấn vào liên kết và truy cập vào trang web BTCK.<br/><br/> 3. Bạn nhận thưởng.<br/> - Mỗi lượt nhấp vào liên kết giới thiệu của bạn sẽ mang lại 0.005 BTCK.<br/><br/>- Chương trình liên kết giới thiệu sẽ kết thúc vào ngày 30/06/2025. Hãy tham gia để không bỏ lỡ cơ hội này!<br/> - Nhận 0.005 BTCK mỗi khi có người dùng tham gia thông qua liên kết giới thiệu của bạn.<br/> - Lưu ý: Phần thưởng có thể không được cộng dồn nếu có nhiều yêu cầu liên tiếp từ cùng một địa chỉ IP.<br/> - Mỗi địa chỉ IP được phép tham gia chương trình tối đa 3 lần mỗi ngày.<br/> - Phần thưởng giới thiệu sẽ được phân phối vào mỗi thứ Hai hàng tuần.<br/> - Số lượng phần thưởng tích lũy không thể kiểm tra trực tiếp, nhưng bạn có thể xem qua địa chỉ ví sau:<br/> 0xf5d52cbc404d5b1d76457a67ca92901bbfa55e3c`,
    }
}

const _LanguageChinese = () => {
    return {
        'menu-home': '主页',
        'menu-wallet': '钱包/挖矿',
        'menu-repository': '资源库',
        'menu-openSource': '开源',
        'whitepaper-title': "比特币氪的白皮书",
        'lang-text-EN': '英语',
        'lang-text-KO': '韩语',
        'lang-text-VI': '越南语',
        'lang-text-ZH': '中文',
        'mode-wallet': '钱包',
        'mode-guide': 'Guide',     
        'root-banner-button': "Let's Start",
        'home-content-header-mining': '开始挖矿',
        'home-content-header-wallet': '创建钱包',  
        'home-content-body-top': '比特币氪的开发完全遵循原版比特币白皮书的初衷，旨在创建一个无需可信第三方干预即可运行的点对点（P2P）金融程序。',
        'home-content-body-bottom': '<br/>比特币氪的开发完全遵循原版比特币白皮书的初衷，旨在创建一个无需可信第三方干预即可运行的点对点（P2P）金融程序。<br/>​<br/>自2009年运行至今的比特币区块链，自2017年起引入了隔离见证 (SegWit)，这严重削弱了中本聪 (Satoshi Nakamoto) 提出的“无第三方干预”这一理念<br/>​<br/>闪电网络（The Lightning Network）是一个独立于比特币的第三方网络。<br/>​<br/>因此，比特币已经丧失了其原本的价值定位。<br/>​<br/>区块链的初始阶段不可避免地需要外部力量（第三方介入）来推动。<br/>然而，此后应杜绝任何外部干预，这一理念基于对区块链完整性的哲学性思考。<br/>​<br/>比特币氪 是一个具备可持续性的区块链系统，无需未来任何更新，并完全排除可信第三方的干预',
        'home-content-tail-title': '这一前提条件如下：',
        'home-content-tail-text0': '初始源代码（包括创世区块）已在过去生成，并在未来不可被任何外部力量更改。',
        'home-content-tail-text1': '这是一个明确声明：原始源代码在任何情况下都不得更改。<br/>然而，允许将其以100%可逆且完全兼容原始源代码的形式移植到新的编程语言中。',
        'home-content-tail-text2': "牛顿的苹果象征着对自然法则的发现。<br/>苹果从树上掉落，这不是好坏的问题，而是法则的体现。<br/>同样，比特币氪 在早期设定的规则，任何情况下都不得更改",
        'home-content-tail-text3': '此主页旨在支持比特币氪的初期运行，仅会短期存在。<br/>由于域名属于个人或组织等第三方资产，可能影响比特币氪存续的域名将仅运营10年，之后将停止使用。',
        'home-content-tail-text4': '负责广播主网（包括创世区块）的种子节点（Seed Node）在初期（10年内）将依赖于互联网域名。<br/>然而，广播的服务器信息在未来将不再提供，而该功能的自动化改进将交由未来的开发者完成。',
        'home-content-tail-text5': '原始种子节点将被移除，未来的开发者将负责在没有种子节点的情况下继续运行区块链。',
        'home-content-tail-text6': '所有最初提供的钱包存储、Explorer网站、源代码和白皮书等存储位置都不应被信任，因为管理实体可能会消失。<br/>所有证明应以 2024 年 11 月 1 日通过 bitcoinkrypton.org 发布的内容为准。',
        'home-content-tail-text7': '比特币氪是一个无需更新且未来始终无管理实体的点对点（P2P）金融程序，标志着最完美的区块链加密货币的诞生。',
        'mode-miner': '挖矿',
        'mining-text-more': '阅读更多',
        'mining-text-hide': '隐藏',
        'mining-intro': `赚取比特币氪的最基本方式是参与工作量证明(PoW)<br/><br/>要参与工作量证明(PoW), 请访问网页顶部的 挖矿 页面，您将看到以下界面。`,
        'mining-text': `按下播放按钮，您将使用计算机的 CPU 启动比特币氪挖矿。​<br/> 当您尝试挖矿时, 系统会将您连接到多个矿池中适合您计算机的最佳挖矿池，从而实现多人共同挖矿。​<br/> 随着矿工数量的增加，挖矿效率将逐渐降低。​<br/><br/> 您参与得越早，获得比特币氪的机会就越高。​<br/><br/> 比特币氪支持基于 JavaScript 虚拟机的挖矿，并且由于其积极限制大容量超级节点的挖矿，比特币氪的挖矿比原始比特币更容易。<br/>即使在移动环境中也可以进行挖矿。​<br/><br/>不要犹豫，参与吧！​<br/><br/>`,
        'donation-start': '捐赠开始',
        'donation-finish': '捐赠完成',
        'donation-text': `您可以通过捐赠我们获取BTCK。<br/> 您的捐赠将对BTCK的初期发展起到巨大作用。<br/><br/> 捐赠可通过 USDT 完成，交易地址如下。<br/><br/> 捐赠地址： 0xbc0889EBeD112B6fDc8A3Eca1cb1E009926D386f<br/> 可捐赠的代币: USDT(Ethereum, Polygon, Binance)<br/> 捐赠方式: 如果您将 USDT 发送到以上地址, BTCK 将按您发送的 USDT 数量按比例发送到。<br/><br/> 基于捐赠的奖励政策<br/> 总共有 3 个捐赠阶段。<br/><br/> 第 1 阶段: 1 USDT 换 1 BTCK, 总量为 100,000 BTCK<br/> 第 2 阶段: 2 USDT 换 1 BTCK, 总量为 200,000 BTCK<br/> 第 3 阶段: 3 USDT 换 1 BTCK, 总量为 300,000 BTCK<br/>`,
        'referral-title': '推荐',
        'accounts-wallet-referral': '推荐链接',
        'referral-link-text': '标准推荐链接:',
        'copy-link': '复制链接',
        'referral-text-top': `在“生成的钱包地址”下方有一个推荐按钮。<br/>通过分享推荐链接，您可以获得 BTCK 奖励。<br/>例如，如果有 200 名用户通过您分享的链接访问，您将获得 1 BTCK 的推荐奖励。`,
        'referral-text-bottom': `工作原理<br/><br/> 1. 创建推荐链接<br/> 进入 钱包 页面生成推荐链接，有以下三种选项：<br/> - 标准推荐链接：可直接分享给他人。<br/> - Shortner 链接: 简洁紧凑，更方便分享。<br/> - HTML 小部件代码: 将此小部件嵌入博客或网站<br/><br/> 2. 他们点击<br/> 将推荐链接发送给朋友，或发布在个人博客和社交媒体上。<br/> 他们点击链接并访问 BTCK 官方网站。<br/><br/> 3. 您获得奖励<br/> - 每次有人通过您的推荐链接点击，您将获得 0.005 BTCK 的奖励。<br/><br/>- 推荐奖励活动将于2025年6月30日结束，抓住机会立即参与！<br/> - 每位通过您的推荐链接参与的用户，您将获得0.005 BTCK奖励。<br/> - 注意：如果同一IP地址连续多次请求推荐奖励，奖励可能不会累积。<br/> - 每个IP地址每天最多可参与推荐活动3次。<br/> - 推荐奖励将在每周一UTC时间发放。<br/> - 累计奖励数量无法直接查看，但您可以通过以下钱包地址查询：<br/> 0xf5d52cbc404d5b1d76457a67ca92901bbfa55e3c`,
    }
}

const _LanguageEnglish = () => {
    return {
        'menu-home': 'Home',
        'menu-wallet': 'Wallet/Mining',
        'menu-repository': 'Repository',
        'menu-openSource': 'Open Source',
        'whitepaper-title': "Bitcoin Krypton's White Paper",
        'lang-text-EN': 'English',
        'lang-text-KO': 'Korean',
        'lang-text-VI': 'Vietnamese',
        'lang-text-ZH': 'Chinese',
        'mode-wallet': 'Wallet',
        'mode-guide': 'Guide', 
        'root-banner-button': "Let's Start",
        'home-content-header-mining': 'Start Mining',
        'home-content-header-wallet': 'Generate Wallet',  
        'home-content-body-top': 'Bitcoin Krypton was developed to fully fulfill its original purpose of creating a peer-to-peer financial program that can operate without the intervention of a trusted third party, in accordance with the original Bitcoin whitepaper.',
        'home-content-body-bottom': `<br/>The Bitcoin blockchain, which has been in operation since 2009, has had SegWit applied since 2017, which has severely undermined Satoshi Nakamoto's slogan of "no third-party intervention."<br/><br/> The Lightning Network is a third-party network that has no connection to Bitcoin.<br/><br/> As a result, Bitcoin has lost its value.<br/><br/> The beginning of a blockchain is inevitably created by some external force (intervention by a third party). <br/>However, there should be no intervention by any external force after that, and this is derived from a philosophical consideration of what makes the blockchain complete.<br/><br/> Bitcoin Krypton is a sustainable blockchain that does not require any future updates and without the intervention of a trusted third party.`,  
        'home-content-tail-title': 'The premise for this is as follows:',
        'home-content-tail-text0': 'The initial original source code, including the genesis block, was created in the past and cannot be changed by any external force in the future.',
        'home-content-tail-text1': 'This is a declaration that the original source code cannot be changed for any reason.<br/> However, the freedom to transplant to a new program language in a form that is 100% reversible and compatible with the original source code is allowed.',
        'home-content-tail-text2': "Newton's apple is the discovery of a law of nature. <br/>An apple falls from above, and this is not a matter of good or bad, but a matter of law. <br/>The rules set in the early days of Bitcoin Krypton cannot be changed for any reason.",
        'home-content-tail-text3': 'This homepage was created for the purpose of the initial settlement of Bitcoin Krypton, and it will only exist for a short time. <br/>Since the domain is an asset belonging to a third party such as an individual or organization, a domain that can affect the existence of Bitcoin Krypton will only be operated for a period of 10 years and will not be used after that',
        'home-content-tail-text4': 'The seed node responsible for broadcasting the mainnet, including the genesis block, will depend on the internet domain for the time being (10 years). <br/>However, the server information being broadcasted will no longer be provided in the future, and the improvement of the program that automates this is aimed at future developers.',
        'home-content-tail-text5': 'The original seed node will disappear, and the task will be left to future generations to operate the blockchain without the seed node.',
        'home-content-tail-text6': 'All places that store wallets, explorers, source codes, white papers, etc. <br/>provided initially should not be trusted because the management entity may disappear, and all proofs should be based on the contents announced through bitcoinkrypton.org on November 1, 2024.',
        'home-content-tail-text7': 'Bitcoin Krypton is a P2P financial program that has no updates and will not have a management entity at any point in the future, and this is talking about the birth of the most perfect blockchain-based cryptocurrency.',
        'mode-miner': 'Mining', 
        'mining-text-more': 'Read More',
        'mining-text-hide': 'Hide',
        'mining-intro': `The most basic way to earn Bitcoin Krypton is to participate in Proof-of-Work (PoW)<br/><br/>To participate in proof of work, select Mining at the top of the webpage and you will see the screen below.`,
        'mining-text': `By pressing the play button, you will activate Bitcoin Krypton mining using your computer's CPU<br/>When you try mining, you will be connected to a mining server optimized for your computer among several mining pools, allowing multiple people to mine together.​<br/>As the number of miners in a mining pool increases, the efficiency of mining decreases.​<br/>​<br/>Bitcoin Krypton supports JavaScript virtual machine-based mining, and since it's actively restrict mining of large-capacity supernodes is much easier than mining of the original Bitcoin.<br/>Mining is possible even in mobile environments.​<br/>​<br/>Don't hesitate, get involved!<br/>​<br/>`,
        'donation-start': 'Start Donation',
        'donation-finish': 'Finish Donation',
        'donation-text': `You can get Bitcoin Krypton through donation.<br/>Your donation will greatly help in the initial arrival of Bitcoin Krypton.<br/><br/> Donations can be made through USDT, and the donation address is as follows.<br/><br/> Donation Address: 0xbc0889EBeD112B6fDc8A3Eca1cb1E009926D386f<br/> Donationable Coin: USDT(Ethereum, Polygon, Binance)<br/> How to donation: If you send USDT to the donation address above, Bitcoin Krypton will be resent to the address you sent it to in proportion to the USDT you sent.<br/><br/> Compensation policy based on donation<br/> There are a total of 3 donation stages.<br/><br/> Stage 1 rewards 1 BTCK per 1 USDT, total amount 100,000 BTCK.<br/> Stage 2 rewards 1 BTCK per 2 USDT, total amount 200,000 BTCK.<br/> Stage 3 rewards 1 BTCK per 3 USDT, total amount 300,000 BTCK.<br/>`,
        'referral-title': 'Referral',
        'accounts-wallet-referral': 'Referral Link',
        'referral-link-text': 'Standard Link:',
        'copy-link': 'Copy Link',
        'referral-text-top': `There is a referral button located below "The generated wallet address".<br/> By sharing the referral link, you can receive BTCK rewards.<br/>For example, if 200 users access the link you shared, you will receive a referral reward of 1 BTCK.`,
        'referral-text-bottom': `Here's how it works.<br/><br/> 1. Create the Referral Link<br/> Visit the Wallet page to create the link, of which:<br/> - Standard Link: Share it directly with others.<br/> - Shortner Link: A more compact version for easy sharing.<br/> - HTML Widgets Code: Embed this widget on your blog or website.<br/><br/> 2. They click<br/> Send the link to your friends, personal blogs or social media.<br/> They click and visit BTCK website.<br/><br/> 3. You earn<br/> - When your friends/traffic click, you will earn 0.005 BTCK for every person who clicks on your referral link.<br/><br/>- The referral reward event runs until June 30, 2025. Don't miss out!<br/> - Earn 0.005 BTCK for every user who participates using your referral link.<br/> - Referral rewards may not accumulate if multiple requests are made consecutively from the same IP.<br/> - Each IP address can participate in the referral up to 3 times per day.<br/> - Referral rewards are distributed every Monday.<br/> - You cannot view your total referral rewards directly, but they can be checked through the wallet address below:<br/> 0xf5d52cbc404d5b1d76457a67ca92901bbfa55e3c`,
    }
}

const _getDataLang = () => {
    switch (localStorage.language) {
        case 'KO':
            return _LanguageKorean();
        case 'VI':
            return _LanguageVietnamese();
        case 'ZH':
            return _LanguageChinese();
        default:
            return _LanguageEnglish();
    }
}

export const _setLanguage = () =>  {
    const langData = _getDataLang();
    $("[text-lang]").each((idx, el) => {
        el.innerHTML = langData[el.attributes['text-lang'].textContent];
    })
}

export const _getLanguage  = (attr) => {
    const langData = _getDataLang();
    return langData[attr];
}

export const _initLanguage = () => {
    switch (navigator.language) {
        case 'ko':
        case 'ko-KR':
            localStorage.language = 'KO';
            break;
        case 'vi':
            localStorage.language =  'VI';
            break;
        case 'zh':
        case 'zh-CN':
        case 'zh-TW':
            localStorage.language =  'ZH';
            break;
        default:
            localStorage.language =  'EN';
            break;
    }
    _setLanguage();
}