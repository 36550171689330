import { useLocation } from 'react-router-dom';
import $ from 'jquery';

import Banner from './banner';
import HomeContents from './homeContents';
// import Mining from './mining';
// import FromFEE from './fromFee';
import Guide from './guide';
import Repository from './repository';

const walletHrefIndex = 3;

const callReferralRequest = (address) => {
    try {
        $.ajax({
            url: (process.env.REACT_APP_REF_URL + address),
            method: "GET",
            dataType:'json',
        })
        return true
    } catch (e) {
        return false
    }
}

const getWallet = (str) => {
    try {
        if (typeof str != 'string') return false;
        str = str.trim()
        str = str.toLowerCase()
        if(str.indexOf('0x') === 0) str = str.replace('0x', '');
        if (str.length != 40) return false;
        if (!(/^[0-9A-Fa-f]*$/.test(str))) return false;
        return callReferralRequest(str);     
    } catch (e) {
        return false
    }
} 

const Root = () => {
    const checkClickedMenu = document.getElementsByClassName('headerMenus')[0]
    if(checkClickedMenu) checkClickedMenu.classList.remove('clicked');
    
    const getLocation = ((useLocation().pathname).toLowerCase()).split('/');

    if(getWallet(getLocation[1])) return (<><Banner/></>)
    else if(getLocation[1] === 'home') return (<><HomeContents/></>)
    // else if(getLocation[1] === 'mining') return (<><Header/><Mining/></>)
    // else if(getLocation[1] === 'fromfee') return (<><Header/><FromFEE/></>)
    else if(getLocation[1] === 'guide') return (<><Guide/></>)
    else if(getLocation[1] === 'repository') return (<><Repository/></>)
    else if(getLocation[1] === 'wallet') return window.open("http://wallet0"+ (Math.floor((Math.random() * walletHrefIndex) + 1)) + process.env.REACT_APP_DOMAIN_URL), (<><HomeContents/></>);
    else if(getLocation[1] === '') return (<><Banner/></>)
    else {
        return (<></>)
    }
};

export default Root;